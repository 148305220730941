.socials {
    padding: 0;
    margin-bottom: 15px;

    &__item {
        display: block;
        padding-right: 20px;

        transition: .2s all;

        &:hover {
            transition: .2s all;
            transform: scale(1.3);
        }
    }

    &__image {
        vertical-align: middle;
        width: 30px;
        height: 30px;
    }

    &_white &__image {
        fill: var(--color__white);
    }

    &_black {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }

    &_black &__image {
        fill: var(--color__black);
    }    
}
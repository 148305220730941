.hidden {
    visibility: hidden;
    opacity: 0;
}

.modal {
    overflow: auto;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, .85);

    &__form {
        padding: 25px 0;


        z-index: 4;

        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);

        max-width: 600px;

        display: flex;
        flex-direction: column;
    }

    &__label {
        margin-bottom: 10px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        font-size: 2.2rem;
        letter-spacing: 0.15em;

        &_privacy {
            color: var(--color__white);

            font-family: 'Lato', sans-serif;
            font-weight: 300;
            font-size: 1rem;
            letter-spacing: 0.15em;

            a {
                color: #2374ff;
                transition: .3s all;

                &:hover {
                    text-decoration: underline;

                    color: #5b97ff;
                    transition: .3s all;
                }
            }
        }
    }

    &__input {
        padding: 10px 15px;
        margin-bottom: 20px;

        font-family: 'Lato', sans-serif;
        font-weight: 300;
        font-size: 1.9rem;
        letter-spacing: 0.15em;

        border: none;
        border-radius: 10px;
    }

    &__select,
    &__option,
    &__textarea {
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        font-size: 1.9rem;

        border: none;
        border-radius: 10px;
    }

    &__select {
        padding: 10px 15px;
        margin-bottom: 20px;

        cursor: pointer;
    }

    &__textarea {
        padding: 10px 15px;
        resize: none;
        height: 15rem;
    }

    &__privacy {
        margin-top: 15px;

        display: flex;
        align-items: center;
    }

    &__checkbox {
        display: block;

        margin-right: 15px;
        width: 45px;
        height: 45px;

        cursor: pointer;
    }

    &__submit {
        position: static;
        padding: 5px;
        margin: 15px auto 0;

        width: 50%;


        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        font-size: 1.5rem;
        letter-spacing: 0.25rem;

        text-transform: uppercase;

        cursor: pointer;
    }

    &__close {
        position: absolute;
        top: -30px;
        right: -30px;
        width: 20px;
        height: 20px;

        cursor: pointer;

        &:hover {
            transform: rotate(90deg);
            transition: .3s all;
        }

        transition: .3s all;
    }

    &__close::before,
    &__close::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 20px;
        background-color: var(--color__white);
    }

    &__close::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &__close::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &__msg {
        z-index: 2;

        display: flex;
        align-items: center;

        position: absolute;
        top: 50vh;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        border-radius: 25px;

        padding: 25px 50px;

        text-align: center;
        background-color: var(--color__white);

        font-family: 'Lato', sans-serif;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 2rem;
        letter-spacing: 0.05em;

        min-width: 600px;

        img {
            padding-right: 10px;
            max-width: 25%;
            height: auto;
        }

        // span {
        //     color: var(--color__white);
        // }
    }

    &__msg.err {
        color: rgb(204, 0, 0);
    }

    &__msg.success {
        color: rgb(0, 151, 0);
    }

    &__msg.spinner {
        display: flex;
        justify-content: center;
        background-color: transparent;

        img {
            width: 150px;
        }
    }

    label.error {
        color: #ff6c6c;
        margin: -15px 0 15px;
    }

    #privacy-error {
        position: absolute;
        left: -3%;

        font-size: 1.5rem;
    }
}
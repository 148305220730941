.menu {
    display: none;

    z-index: 999;
    position: fixed;

    width: 100vw;
    padding: 5px 15px;

    align-items: center;
    justify-content: space-between;

    background-color: rgba(13, 13, 13, .8);
}

.burger {
    font-size: var(--font-size);
    cursor: pointer;
    padding: 3em 1.5em; ///////////////// touch area size

    &>div {
        width: 36px;
        height: 3px;
        border-radius: 3px;
        background: white;

        &::before,
        &::after {
            transition: font-size 0s;
            content: "";
            display: block;
            position: absolute;
            width: 36px;
            height: 3px;
            background: white;
            border-radius: 3px;
        }

        &::before {
            margin-top: -1.5em;
        }

        &::after {
            margin-top: 1.5em;
        }
    }

    transition: all 0.7s,
    font-size 0s;
    transition-timing-function: cubic-bezier(0.68, -0.35, 0.265, 1.35);

    &>div {
        transition: all 0.1s 0.3s, font-size 0s;

        &::before,
        &::after {
            transition: all 0.3s 0.2s, font-size 0s;
        }
    }

    &.toggled {
        transform: rotate(180deg);

        >div::before {
            transform: rotate(45deg) translate(1.1em, 1em);
        }

        >div {
            background: transparent;
        }

        >div::after {
            transform: rotate(-45deg) translate(1.1em, -1em);
        }
    }
}

.nav {
    display: none;

    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    transform: translateX(100vw);

    &.toggled {
        transform: translateX(0);
    }

    transition: all .5s;

    &__list {
        display: block;
        width: inherit;
        height: inherit;
     

        padding: 60px 30px;

        background-color: rgba(13, 13, 13, .8);

        list-style: none;

        font-family: 'Montserrat',
            sans-serif;
        font-weight: 700;
        font-size: 3.1rem;
        line-height: 1.2;

        text-transform: uppercase;

        text-align: right;
    }

    &__item {
        margin-top: 20px;
    }

    &__link {
        color: var(--color__white);
    }
}
.about {
    margin-top: 60px;

    color: var(--color__black);

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(12, 69px);
        grid-gap: 30px;
    }

    &__photos {
        position: relative;

        grid-column-start: 1;
        grid-column-end: 7;

        width: 100%;
    }

    &__image {
        display: block;

        &_bottom {
            z-index: 1;

            width: 100%;
        }

        &_top {
            z-index: 2;
            position: absolute;

            bottom: 70px;
            right: 0;
            transform: translateX(calc(50% + 15px));
        }
    }

    &__content {
        grid-column-start: 7;
        grid-column-end: 13;

        display: grid;
        grid-template-columns: repeat(6, 69px);
        grid-template-rows: 260px auto;
        column-gap: 30px;
    }

    &__title {
        grid-column-start: 1;
        grid-column-end: 7;

        align-self: center;

        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 3.1rem;
        line-height: 1.2;

        text-transform: uppercase;
    }

    &__descr {
        grid-column-start: 3;
        grid-column-end: 7;

        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.5;

        letter-spacing: 0.15em;
        text-transform: uppercase;
    }
}
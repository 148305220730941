.terms {
    margin-top: 60px;

    color: var(--color__black);


    &__title {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 3.1rem;
        line-height: 1.2;

        text-transform: uppercase;
    }

    &__list {
        margin-top: 30px;
    }

    &__item {
        margin-top: 15px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.5;

        letter-spacing: 0.15em;
        text-transform: uppercase;

        list-style-type: "\1F5A4";


        &_note {
            position: relative;

            font-size: 1rem;

            &::before {
                position: absolute;
                left: -10px;
                top: -4px;

                content: '*';
            }
        }
    }
}
.logo {
    font-family: 'Montserrat', sans-serif;;
    font-weight: 300;
    font-size: 4rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;

    &_white {
        color: var(--color__white);
    }

    &_black {
        font-size: 2.6rem;
        letter-spacing: 0.15em;

        color: var(--color__black);
    }

    &_menu {
    }
}
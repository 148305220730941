 .firstscreen {
    position: relative;
    
    height: 100vh;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.28) 26.04%, rgba(0, 0, 0, 0) 100%), no-repeat left/cover url(../../img/firstscreen_bg.webp);
    background-attachment: fixed;


    &__caption {
        position: absolute;
        right: 140px;
        bottom: 25%;

        color: var(--color__white);

        font-family: 'Lato', sans-serif;
        font-weight: 300;
        font-size: 1.3rem;
        line-height: 150%;

        text-align: right;
        letter-spacing: 0.15em;
        text-transform: uppercase;
    }
}
.button,
.button-mobile,
.modal__submit {
    @include no-btn;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding: 1rem 1.6rem .9rem;
    border-radius: 20px;

    background: transparent;

    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;

    transition: .3s all;

    &_white {
        border: 0.2rem solid var(--color__white);
        color: var(--color__white);

        &:hover {
            background: var(--color__white);
            color: var(--color__black);

            transition: .3s all;
        }
    }

    &_black  {
        border: 0.2rem solid var(--color__black);
        color: var(--color__black);

        &:hover {
            background: var(--color__black);
            color: var(--color__white);

            transition: .3s all;
        }
    }


}

.button-mobile {
    width: 100%;
    display: none;
}
.contacts {
    display: flex;
    flex-direction: column;

    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.3rem;
    letter-spacing: 0.15em;

    text-align: right;

    &__link {
        text-decoration: none;
        color: var(--color__black);

        &:hover {
            color: var(--color__black);
        }
    }

    a {
        margin-bottom: 15px;
    }

    a:last-child {
        margin-bottom: 0;
    }
}
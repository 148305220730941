@import "D:/Web/Satsana/src/scss/base/_01_normalize.scss";
@import "D:/Web/Satsana/src/scss/base/_mixins.scss";

@import "D:/Web/Satsana/src/scss/global/_fonts.scss";
@import "D:/Web/Satsana/src/scss/global/_global.scss";
@import "D:/Web/Satsana/src/scss/global/_svg.scss";
@import "D:/Web/Satsana/src/scss/global/_vars.scss";

@import "D:/Web/Satsana/src/scss/_local-fonts.scss";
@import "D:/Web/Satsana/src/scss/base/_01_normalize.scss";
@import "D:/Web/Satsana/src/scss/base/_mixins.scss";
@import "D:/Web/Satsana/src/scss/global/_fonts.scss";
@import "D:/Web/Satsana/src/scss/global/_global.scss";
@import "D:/Web/Satsana/src/scss/global/_svg.scss";
@import "D:/Web/Satsana/src/scss/global/_vars.scss";

@import "D:/Web/Satsana/src/components/bem-blocks/about/_about.scss";
@import "D:/Web/Satsana/src/components/bem-blocks/button/_button.scss";
@import "D:/Web/Satsana/src/components/bem-blocks/contacts/_contacts.scss";
@import "D:/Web/Satsana/src/components/bem-blocks/firstscreen/_firstscreen.scss";
@import "D:/Web/Satsana/src/components/bem-blocks/footer/_footer.scss";
@import "D:/Web/Satsana/src/components/bem-blocks/header/_header.scss";
@import "D:/Web/Satsana/src/components/bem-blocks/logo/_logo.scss";
@import "D:/Web/Satsana/src/components/bem-blocks/menu/_menu.scss";
@import "D:/Web/Satsana/src/components/bem-blocks/modal/_modal.scss";
@import "D:/Web/Satsana/src/components/bem-blocks/photography/_photography.scss";
@import "D:/Web/Satsana/src/components/bem-blocks/socials/_socials.scss";
@import "D:/Web/Satsana/src/components/bem-blocks/terms/_terms.scss";


.ofHidden {
    overflow: hidden;
}

html,
body {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

@media (max-width: 1200px) {
    html {
        font-size: 9px;
    }

    .container {
        max-width: 728px;
    }

    .firstscreen {
        background-attachment: scroll;
        background-position: 35%;

        &__caption {
            right: 5%;
        }
    }

    .about {
        margin-top: 30px;

        &__wrapper {
            grid-template-columns: repeat(12, 1fr);
            grid-gap: 15px;
        }

        &__image {
            &_top {
                max-width: 220px;
                max-height: 220px;

                bottom: 14%;
                right: 0;
                transform: translateX(calc(50% + 15px));
            }
        }

        &__content {
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: 170px auto;
            column-gap: 15px;
        }
    }

    .terms {
        margin-top: 30px;

        padding: 0px 15px;

        &__list {
            margin-top: 20px;
        }
    }

    .photography {
        margin-top: 30px;

        &__tiles {
            width: 100%;
            grid-gap: 10px;
            grid-template-columns: repeat(8, minmax(auto, 1fr));
            grid-template-rows: repeat(3, 169px);
        }

        .container {
            position: relative;
            min-height: 527px;
        }
    }

    .tab {
        &__content {
            &-wrapper {
                max-width: 35%;
            }
        }
    }

    .footer {
        &__wrapper {
            min-height: 100px;
        }
    }

    .modal {
        &__label {
            &_privacy {
                font-size: 1.4rem;
            }
        }

        &__checkbox {
            width: 45px;
            height: 45px;
        }

        &__msg {
            font-size: 1.4rem;
            line-height: 2rem;
            letter-spacing: 0.05em;
        }

        label.error {
            font-size: 1.5rem;
        }
    }
}

@media (max-width: 768px) {
    html {
        font-size: 8px;
    }

    .firstscreen {
        position: relative;
        background-position: 37%;

        &__caption {
            right: 20px;

            max-width: 190px;
        }
    }

    .menu {
        display: flex;
    }

    .nav {
        display: block;
    }

    .header {
        position: static;

        height: auto;
        width: 100%;

        &__wrapper {
            padding-top: 20px;

            height: auto;
            min-height: min-content;
            width: 100%;
        }

        &__logo {
            display: none;
        }
    }

    .socials {
        &_white {
            display: none;
        }
    }

    .button {
        &_white {
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .about {
        overflow: hidden;

        &__wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
        }

        &__photos {
            width: 75%;
        }

        &__image {
            &_bottom {
                filter: blur(2px);

                transform: translateX(-5%);
            }
        }

        &__content {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 500;

            max-width: 70%;
            padding: 15px;

            color: var(--color__white);
            display: flex;
            flex-direction: column;
        }

        &__title {
            align-self: start;
            font-size: 4vw;
            margin-bottom: 25px;
        }

        &__descr {
            font-size: 2vw;
            letter-spacing: 2.5px;
        }

        &__image {
            &_top {
                max-width: 57%;
                max-height: 57%;
                bottom: 20%;

                right: 0;
                transform: translateX(calc(50% + 15px));
            }
        }
    }

    .photography {
        margin-top: 0;

        &__img {
            height: 95vh;

            // filter: brightness(.7);
            z-index: 1;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(to bottom, rgba(13, 13, 13, .4) 0, rgba(13, 13, 13, .1) 15%, rgba(13, 13, 13, .1) 50%, rgba(13, 13, 13, .75) 100%);
            }

            &_additional {
                display: none;
            }
        }

        &__title {
            &_mobile {
                position: absolute;
                top: 25px;
                left: 25px;

                font-size: 5vw;

                display: block;
            }
        }

        &__descr {
            font-size: 3vw;
            letter-spacing: 2.5px;
        }

        &__price {
            font-size: 4.5vw;
        }

        &__title_mobile,
        &__descr,
        &__price {
            color: var(--color__white);
            z-index: 2;
        }

        &__tiles {
            display: block;
            width: 100%;
            height: 100%;

            padding: 0;
        }

        .container {
            padding: 0 15px;
        }
    }

    .tab {
        &__link {
            &-wrapper {
                display: none;
            }
        }

        &__content {
            position: relative;
            margin-top: 25px;


            &-wrapper {
                position: absolute;
                bottom: 25px;
                left: 25px;

                max-width: 85%;
            }

            .button {
                display: none;
            }
        }
    }

    .footer {
        &__wrapper {
            flex-direction: column;
            justify-content: space-around;

            min-height: 120px;
        }

        .logo {
            display: none;
        }

        .socials {
            position: static;
            transform: none;


        }

        .contacts {
            text-align: center;
            font-size: 2vw;
        }
    }

    .modal {
        &__form {
            top: 2%;
            min-width: 400px;
        }

        &__label {
            &_privacy {
                font-size: 1.4rem;
            }
        }

        &__msg {
            min-width: 400px;
            font-size: 1.6rem;
            line-height: 2rem;
            letter-spacing: 0.08em;
        }
    }

    .button,
    .button-mobile {
        font-size: 2vw;
    }

    .button-mobile {
        display: block;
        margin: 15px auto 0;
        width: 50%;
    }
}


@media (max-width: 480px) {
    .about {
        margin-top: 20px;

        &__content {
            max-width: 58%;
        }

        &__title {
            align-self: start;
            font-size: 5vw;
            margin-bottom: 25px;
        }

        &__descr {
            font-size: 3vw;
            letter-spacing: 2.5px;
        }
    }

    .terms {
        margin-top: 20px;

        &__list {
            margin-top: 15px;
        }

        &__item {
            padding-left: 2px;
            margin-top: 10px;
        }
    }

    .photography {
        margin-top: 20px;

        &__title_mobile {
            top: 10px;
            left: 10px;
        }
    }

    .tab {
        &__content {
            margin-top: 10px;

            &-wrapper {
                bottom: 10px;
                left: 10px;
            }
        }
    }


    .footer {
        &__wrapper {
            min-height: auto;
        }

        .socials {
            &__item {
                padding-right: 10px;
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .contacts {
            a:first-child {
                margin-bottom: 10px;
            }
        }
    }

    .modal {

        &__form {
            padding-top: 30px;
            min-width: 300px;
        }

        &__label {
            &_privacy {
                font-size: 1.3rem;
            }
        }

        &__checkbox {
            width: 50px;
            height: 50px;
        }

        &__msg {
            padding: 35px 25px;

            min-width: 300px;
            font-size: 1.5rem;
            line-height: 2rem;
            letter-spacing: 0.05em;

            .modal__close {
                padding: 15px;

                top: -35px;
                right: 5px;
            }
        }

        &__close {
            top: 15px;
            right: 0;
        }

        #privacy-error {
            left: -2%;
        }
    }

    .button,
    .button-mobile {
        font-size: 4vw;
    }

    .button-mobile {
        display: block;
        margin: 10px auto 0;
        width: 75%;
    }
}
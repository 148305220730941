.photography {
    margin-top: 60px;

    position: relative;

    &__title {
        &_mobile {
            display: none;

            color: var(--color__black);
            font-family: 'Montserrat', sans-serif;
            font-size: 3vw;
            font-weight: 700;
            letter-spacing: 2.7px;
            text-transform: uppercase;
        }
    }

    &__descr {
        color: var(--color__black);

        font-family: 'Lato', sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 3.6px;
        text-transform: uppercase;

        max-width: 466px;
    }

    &__price {
        margin-top: 2rem;

        font-family: 'Montserrat', sans-serif;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 4.8px;
        text-transform: uppercase;

        color: var(--color__black);
    }

    &__tiles {
        padding-left: 30px;

        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(8, minmax(auto, 83px));
        grid-template-rows: repeat(3, 280px);
    }

    &__img {
        img, video {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }

        &_main {
            grid-column-start: 1;
            grid-column-end: 6;
            grid-row-start: 1;
            grid-row-end: 4;
        }

        &_additional {
            grid-column-start: 6;
            grid-column-end: 9;
        }
    }

    .container {
        position: relative;
        min-height: 870px;
    }
}

.tab {
    &__link {
        &:hover {
            text-decoration: none;
            color: var(--color__black);
            transform: translateX(.5rem);

            transition: .3s all;
        }

        &-wrapper {
            position: absolute;
            z-index: 2;

            display: flex;
            flex-direction: column;
        }


        color: var(--color__black);

        font-family: 'Montserrat', sans-serif;
        font-size: 2.1rem;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 4.8px;
        text-transform: uppercase;

        cursor: pointer;

        transition: .3s all;

        &_active {
            font-size: 2.6rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 6px;
            text-transform: uppercase;

            &:hover {
                scale: none;
            }
        }
    }



    &__content {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 1;

        display: flex;

        &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        &-wrapper button {
            margin-top: 4rem;
        }

        transition: all .5s ease;
    }
}

